<template>
  <div>
    <eden-page-header :title="'Rider Directory'" :subtitle="'Add New Rider'" />
    <rider-form />
  </div>
</template>

<script>
import RiderForm from "@/components/Logistics/Directory/Riders/RiderForm.vue";
import * as actions from "@/store/action-types";
export default {
  name: "RiderAdd",
  components: {
    RiderForm,
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>
